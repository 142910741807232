import React from 'react'
import { Link } from 'gatsby'
import { StyledNavbar, NavbarLogo, BasketButton } from './style'
import Container from '../elements/container'
import { NavbarBackgroundWhite } from '../elements/bg_nav'
import Basket from '../Basket'

const Navbar = () => (
  <NavbarBackgroundWhite>
    <Container>
      <StyledNavbar>
        <NavbarLogo>
          <Link to="/">
            <img
              src="https://images.prismic.io/cryptruserui/321e0488-fc8d-4ec8-b84b-fffd250ed129_logo_la_maison_du_chocolat.svg?auto=compress,format"
              alt="La maison du chocolat paris - logo"
            />
          </Link>
        </NavbarLogo>
        <Basket />
      </StyledNavbar>
    </Container>
  </NavbarBackgroundWhite>
)

export default Navbar
