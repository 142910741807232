/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

export const NavbarBackgroundWhite = styled.div`
  ${tw`
    bg-white
    border-b
    border-gray-200
    fixed
    w-full
    z-50
  `}
`

export const FooterBackgroundDark = styled.div`
  ${tw`
    bg-transparent
    w-full
    z-50
    left-0
    bottom-0
  `}
`
