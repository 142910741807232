import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { v4 as uuid } from 'uuid'
import { Link } from 'gatsby'
import { BasketButton } from './style'
import { CART_QUERY } from '../../graphql/queries'
import { OrderItem } from '../../types/index'

const orderItemSum = (order_items: Array<OrderItem>): number => {
  const quantities = [...order_items.map((orderItem: OrderItem): number => orderItem.quantity), 0]
  return quantities.reduce(
    (previousOrderItem: number, currentOrderItem: number): number =>
      previousOrderItem + currentOrderItem,
  )
}

const Basket = () => {
  const { data } = useQuery(CART_QUERY)
  return (
    <>
      <BasketButton key={uuid()}>
        <Link to="/panier">
          {orderItemSum(data.cart.order_items)}
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx={8} cy={21} r={2} />
            <circle cx={20} cy={21} r={2} />
            <path d="M5.67 6H23l-1.68 8.39a2 2 0 0 1-2 1.61H8.75a2 2 0 0 1-2-1.74L5.23 2.74A2 2 0 0 0 3.25 1H1" />
          </svg>
        </Link>
      </BasketButton>
    </>
  )
}

export default Basket
