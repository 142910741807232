/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

const Container = styled.div`
  ${tw`
    max-w-7xl
    mx-auto
    px-3
    sm:px-6
    lg:px-8
  `}
`
export default Container
