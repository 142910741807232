import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledNavbar = styled.div`
  ${tw`
    h-16
    bg-white
    flex-shrink-0 
    flex 
    justify-between 
    items-center
  `}
`

export const NavbarLogo = styled.div`
  ${tw`
    lg:w-0
    lg:flex-1
  `}
  a {
    ${tw`flex`}
  }
  img {
    ${tw`
      h-8 w-auto sm:h-10
    `}
  }
`

export const BasketButton = styled.div`
  ${tw`
    flex
    justify-end
    items-center
  `}
    a {
      ${tw`
        inline-flex 
        items-center 
        justify-center
        text-base
        font-medium
        text-gray-900
        focus:outline-none
      `}
      svg {
        ${tw`
          h-6 
          w-auto 
          pl-2
          stroke-current 
          text-gray-900
        `}
      }
    }
  }
`
