import React from 'react'
import { Link } from 'gatsby'
// import { StyledNavbar, NavbarLogo, BasketButton } from './style'
import Container from '../elements/container'
import { FooterBackgroundDark } from '../elements/bg_nav'
import Basket from '../Basket'
import { StyledFooter, FooterLogo } from './style'

const Footer = () => (
  <FooterBackgroundDark>
    <Container>
      <StyledFooter>
        <FooterLogo>
          <img
            src="https://images.prismic.io/cryptruserui/13205a2d-3a46-4b2b-9f38-4f81f0878e76_logo_cryptr_full_color_horizontal.svg?auto=compress,format"
            alt="Cryptr - logo"
          />
        </FooterLogo>
      </StyledFooter>
    </Container>
  </FooterBackgroundDark>
)

export default Footer
