import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledFooter = styled.div`
  ${tw`
    h-16
    flex-shrink-0 
    flex 
    justify-center
    items-center
  `}
`

export const FooterLogo = styled.div`
  img {
    ${tw`
      h-8 w-auto sm:h-10
    `}
  }
`
