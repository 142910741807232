import * as React from 'react'
import { Link } from 'gatsby'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './layout.css'
// import { rhythm } from '../utils/typography'
/*
  <div
    style={{
      margin: `0 auto`,
      marginBottom: rhythm(1.5),
      marginTop: rhythm(1.5),
      maxWidth: 650,
      paddingLeft: rhythm(3 / 4),
      paddingRight: rhythm(3 / 4),
    }}
  >
*/

const MainLayout: React.FC = ({ children }) => (
  <div>
    <a rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    <Navbar>
      <Link to="/panier">Panier</Link>
    </Navbar>
    {children}
    <Footer />
  </div>
)

export default MainLayout
